import React from 'react';
import { Grid, Typography} from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { FacebookOutlined } from '@mui/icons-material';


const Footer = () => {

    const footerMenu = [
        [
            {name: 'About Us', url:'about'},
            {name: 'Our Approach', url:'our-approach'},
            {name: 'Social Commitment', url:'social-commitment'},
            {name: 'Leadership', url:'leadership'},
            {name: 'Careers', url:'careers'},
            {name: 'Name Change ', url:'investors/name-change'}
        ],
        [
            {name: 'Services', url:'services'},
            {name: 'HVAC', url:'services/hvac'},
            {name: 'Firefighting', url:'services/firefighting'},
            {name: 'Plumbing', url:'services/plumbing'},
            {name: 'Electrical', url:'services/electrical'},
            {name: 'IBMS / LV', url:'services/ibms'}
        ],
        [
            {name: 'Products', url:'products'},
            {name: 'Projects', url:'projects'},
            {name: 'Case Studies', url:'case-study'},
            {name: 'Corporate Compliance', url:'corporate-compliance'},
            {name: 'Contact Us', url:'contact'},
            {name: 'Privacy Policy', url:'privacy-policy'}
        ]
    ]


    return (
        <>
        <Grid container sx={{width:'100%', display:'flex', justifyContent:'center' }}>
            <Grid item sx={{display:'flex', width:'100%', maxWidth:1440 }}>
            <Grid item className='footer-div' sx={{}}>
                <Grid item className='footer1-div'  sx={{}}>
                    <Grid item xs={12} sm={3} sx={{pb:'2em'}}>
                        <img src='/assets/images/logo.png' alt='logo' />
                        <Typography className='normal-text title-color' sx={{ml:1.5,  fontWeight:500}} >
                            Full service MEP engineering solutions company
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7} sx={{display:'flex', width:'100%', mx: !isMobile ? '30px' : '15px'}}>
                        {
                            footerMenu.map((fItem, index) => {
                                return <Grid key={`nav_${index}`} item xs={4} sx={{display:'flex', flexDirection:'column'}} >
                                {fItem.map(items => {
                                    return <Link key={items.name} to={items.url} className='normal-text footer-text-color' style={{textDecoration:'none', opacity:0.6, fontSize:'0.8em', marginBottom:'1em'}}>{items.name}</Link>
                                })}
                                </Grid>
                                
                            })
                        }
                    </Grid>
                    <Grid item xs={12} sm={2} sx={{background: !isMobile && 'url(/assets/images/footerbg.png) no-repeat', backgroundPosition:'bottom right', ml: isMobile && 2.5}}>
                        <Grid item sx={{display:'flex', alignItems:'center'}}>
                            <Typography className='normal-text footer-text-color' sx={{fontSize:'0.8em !important', mr: 2, opacity:0.8}} >Stay connected</Typography>
                            <Link to={"https://www.linkedin.com/company/uniqueengineers/"} target='_blank'><img src='assets/images/linkedinicon.svg' sx={{width:16, height:16}} alt="" style={{marginRight:'1em'}} /></Link>
                            <Link to={"https://www.facebook.com/share/4T6FQRqLzJcZ99dP/?mibextid=LQQJ4d"} target='_blank'><FacebookOutlined /></Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12} sx={{height:'3px', mx:1, borderTop:'1px solid #DAE6EF'}}>
        </Grid>
        <Grid container sx={{width:'100%', display:'flex', justifyContent:'center', px:1 }}>
            <Grid item sx={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center', mt:1, pt:2, pb:7.5, }}>
                    <Grid item sx={{display:'flex', alignItems:'center'}}>
                        <Typography className='normal-text' variant='body2' sx={{fontSize:'0.8em !important', opacity:0.6}} component={'p'}>© 2025 Unique Engineers</Typography>
                    </Grid>
                    <p></p>
                    <Typography className='normal-text' variant='body2' component={'p'} sx={{fontSize:'0.8em !important', opacity:0.6}}>By <Link to="https://hudestudio.com" target="_blank" style={{textDecoration:'none'}}> HUDE</Link></Typography>
            </Grid>
        </Grid>
        
        </>
    )

}

export default Footer;