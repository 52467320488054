import React, { useEffect } from 'react';
import { Grid } from '@mui/material';

import Banner from '../../components/banner';
import Enrich from '../../components/enrich';
import FootPrint from '../../components/footprint';
import Leading from '../../components/leading';
import GetKnow from '../../components/getKnow';
import GetusKnow from '../../components/getus';
import Clients from '../../components/clients';

<link rel="canonical" href="https://www.uniqueengineersltd.com/" />

const Home = () => {

    useEffect(() => {
        window.scrollTo({top:0, left:0})
    }, [])


    return (
        <Grid container style={{width:'100%', justifyContent:'center'}}>
            <Grid item xs={12} sx={{width:'100'}} >
            <Grid style={{backgroundColor:'#F4F8FA', width:'100%', display:'flex', justifyContent:'center'}}>
                <Banner />
            </Grid>
            <Enrich />
            <FootPrint title='Our footprint' content='The scope of Unique Engineers has evolved over the years, and it has emerged as a leading full-service MEP solutions partner catering to diverse needs, whether industrial zones or community spaces. For unique solutions to every project, our teams delve into details of every requirement and deliver results that are nothing short of spectacular.' />
            <Leading />
            <GetKnow />
            <GetusKnow />
            <Clients />
        </Grid>
        </Grid>
    )

}

export default Home;